import { createAction } from "../fns/createAction";
import { fns, libTypes, staticData } from "@holta/lib";
import { Address, AddressFields, Customer, Order } from "@holta/lib/types";
import { user } from "../fns/user";
import { AdminStore } from "../config/store";
import { useStore } from "../hooks/useStore";
import { feathersApp } from "../config/feathers";
import { navigate } from "raviger";
import { isEqual } from "lodash";
import { v4 as uuid } from "uuid";
import { toast } from "react-hot-toast";
/**
 * This is a simple higher order function that creates an action that updates a field in the order.
 */

function createBasicFieldAction<
    K extends keyof libTypes.Order,
    UdFn extends (value: any) => libTypes.Order[K]
>(actionName: string, key: K, updateFn: UdFn) {
    return createAction(actionName, (newState, updateValue: Parameters<UdFn>[0]) => {
        try {
            if (!removeMatchingUpdate(key, updateValue, newState)) {
                (newState.editOrder as any).newValues.order = {
                    ...getEditOrderFromStore().newValues.order,
                    [key]: updateFn(updateValue),
                };
            }
        } catch (e) {}
    });
}

export function getEditOrderFromStore() {
    const editedOrder = useStore.getState().editOrder;
    if (editedOrder === null) {
        throw new Error("No order to edit");
    }
    return editedOrder;
}

export function getMergedOrderFromStore() {
    return fns.order.merge(
        getEditOrderFromStore().currentValues.order,
        getEditOrderFromStore().newValues.order
    );
}

function isNewOrderItem(item: libTypes.OrderItem) {
    return !getMergedOrderFromStore().items.find((i) => i.id === item.id);
}

function getMergedOrderItemFromStore() {
    const order = getMergedOrderFromStore();
    return order.items.find((item) => item.id === getEditOrderFromStore().newValues.orderItem.id);
}

function getMergedOrderItemFromStoreWithEdit() {
    const order = getMergedOrderFromStore();
    const existingOrderItem = order.items.find(
        (item) => item.id === getEditOrderFromStore().newValues.orderItem.id
    );
    const editedOrderItem = getEditOrderFromStore().newValues.orderItem;
    return existingOrderItem ? fns.order_item.merge(existingOrderItem, editedOrderItem) : editedOrderItem as libTypes.OrderItem;
}

function removeMatchingUpdate(
    key: keyof libTypes.EditedOrderValues,
    value: any,
    draftState: AdminStore
) {
    const editedOrder = getEditOrderFromStore();
    if (value === editedOrder.currentValues.order[key]) {
        delete (draftState.editOrder?.newValues.order as libTypes.EditedOrderValues)[key];
        return true;
    }

    return false;
}

// Clear the editCustomer state
export const clearAll = createAction("EDIT_ORDER_CLEAR_ALL", (newState) => {
    newState.editOrder = null;
});

// Initialise the state with a new order
export const createNewOrder = createAction(
    "EDIT_ORDER_INITIALISE_NEW",
    (newState, initialValues?: Partial<libTypes.Order>) => {
        if (user.id === null) {
            throw new Error("User is not logged in");
        }
        const order = fns.order.create(user.id, initialValues);

        newState.editOrder = {
            type: "NEW",
            newValues: {
                order: {},
                orderItem: {},
            },
            currentValues: {
                order: order,
                supplierDoorRange: [],
            },
        };
    }
);



export const loadOrderFromLocalStorage = createAction(
    "EDIT_ORDER_LOAD_FROM_LOCAL_STORAGE",
    (newState) => {
        const order = JSON.parse(localStorage.getItem("editOrder") || "{}");
        if (Object.keys(order).length === 0) return;
        const state : any = {
            currentValues: {
                order: {
                    ...order.currentValues.order,
                    items: order.currentValues.order.items ? order.currentValues.order.items.map((item: any) => ({
                        ...item,
                        wEdited : item.wEdited ? item.wEdited : null,
                        hEdited : item.hEdited ? item.hEdited : null,
                        dEdited : item.dEdited ? item.dEdited : null,
                    })) : [],
                }
            },
            newValues: {
                order: {
                    ...order.newValues.order,
                    items: order.newValues.order.items ? order.newValues.order.items.map((item: any) => ({
                        ...item,
                        wEdited : item.wEdited ? item.wEdited : null,
                        hEdited : item.hEdited ? item.hEdited : null,
                        dEdited : item.dEdited ? item.dEdited : null,
                    })) : [],
                }
            },
        }
        newState.editOrder = state;
    });

export const carcaseColour = createBasicFieldAction(
    "EDIT_ORDER_CARCASE_COLOUR",
    "carcaseColour",
    fns.order.set.carcaseColour
);
export const constructionType = createBasicFieldAction(
    "EDIT_ORDER_CONSTRUCTION_TYPE",
    "constructionType",
    fns.order.set.constructionType
);

export const customerRef = createBasicFieldAction(
    "EDIT_ORDER_CUSTOMER_REF",
    "customerRef",
    fns.order.set.customerRef
);

export const deliveryAddress = createAction(
    "EDIT_ORDER_DELIVERY_ADDRESS",
    (newState, address: libTypes.AddressFields) => {
        const existingOrder = getEditOrderFromStore().currentValues.order.deliveryAddress;
        const deliveryAddress = fns.order.set.deliveryAddress(address);

        if (
            Object.keys(deliveryAddress).length === Object.keys(existingOrder).length &&
            Object.keys(deliveryAddress).every(
                (key) =>
                    deliveryAddress[key as keyof typeof deliveryAddress] ===
                    existingOrder[key as keyof typeof deliveryAddress]
            )
        ) {
            //delete newState.editOrder!.newValues.order.deliveryAddress;
        }
        newState.editOrder!.newValues.order.deliveryAddress = deliveryAddress;
    }
);

export const deliveryDate = createAction(
    "EDIT_ORDER_DELIVERY_DATE",
    (
        newState,
        deliveryDate: Order["deliveryDate"],
    ) => {
        newState.editOrder!.newValues.order.deliveryDate = fns.order.set.deliveryDate(deliveryDate);
        newState.editOrder!.newValues.order.deliveryDateType = 'DAY'
    }
);

export const deliveryNotes = createBasicFieldAction(
    "EDIT_ORDER_DELIVERY_NOTES",
    "deliveryNotes",
    fns.order.set.deliveryNotes
);

export const drawerbox = createBasicFieldAction(
    "EDIT_ORDER_DRAWERBOX",
    "drawerbox",
    fns.order.set.drawerbox
);

export const gluedWallUnits = createBasicFieldAction(
    "EDIT_ORDER_GLUEDWALLUNITS",
    "gluedWallUnits",
    fns.order.set.gluedWallUnits
);

export const voidSize = createBasicFieldAction(
    "EDIT_ORDER_VOID_SIZE",
    "voidSize",
    fns.order.set.voidSize
);


/**
 *  ORDER ITEM ACTIONS
 */

export const createCarcaseItem = createAction("CREATE_CARCASE_ORDER_ITEM", (newState) => {
    const newOrderItem = fns.order_item.createCarcaseItem(getMergedOrderFromStore().discount);
    newState.editOrder!.newValues.orderItem = newOrderItem;
});

export const editCarcaseItem = createAction("EDIT_CARCASE_ORDER_ITEM", (newState, id: string) => {
    newState.editOrder!.newValues.orderItem = { id };
});

export const updateCarcaseItemCode = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_CODE",
    (newState, productId: string) => {
        const products = useStore.getState().products;
        const components = useStore.getState().productComponents;
        const product = products.find((p) => p.id === productId);
        if (!product) return;
        newState.editOrder!.newValues.orderItem = fns.order_item.createCarcaseItemFromProduct(
            product,
            components,
            getMergedOrderFromStore().discount,
            getEditOrderFromStore().newValues.orderItem as libTypes.CarcaseOrderItem
        );
    }
);

export const updateCarcaseItemWidth = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_WIDTH",
    (newState, width: number) => {
        if (fns.order_item.isCarcaseItem(getMergedOrderItemFromStoreWithEdit())) {
            const mergedOrderItem = getMergedOrderItemFromStore() as libTypes.CarcaseOrderItem;
            const mergedOrderItemWithEdit =
                getMergedOrderItemFromStoreWithEdit() as libTypes.CarcaseOrderItem;
            if (
                mergedOrderItem?.wEdited === width ||
                mergedOrderItem?.w === width
            ) {
                //delete (newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem).wEdited;
            } else if (
                mergedOrderItemWithEdit.w === width) {
                    (newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem
                ).wEdited = null;
            } else {
                (
                    newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem
                ).wEdited = fns.order_item.set.wEdited(width, mergedOrderItemWithEdit.soldByM2);
            }
        }
    }
);

export const updateCarcaseItemHeight = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_HEIGHT",
    (newState, height: number) => {
        if (fns.order_item.isCarcaseItem(getMergedOrderItemFromStoreWithEdit())) {
            const mergedOrderItem = getMergedOrderItemFromStore() as libTypes.CarcaseOrderItem;
            const mergedOrderItemWithEdit =
                getMergedOrderItemFromStoreWithEdit() as libTypes.CarcaseOrderItem;
            if (
                mergedOrderItem?.hEdited === height ||
                mergedOrderItem?.h === height
            ) {
                //delete (newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem).hEdited;
            } else if (
                mergedOrderItemWithEdit.h === height) {
                    (newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem
                ).hEdited = null;
                
            } else {
                (
                    newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem
                ).hEdited = fns.order_item.set.hEdited(height, mergedOrderItemWithEdit.soldByM2);
            }
        }
    }
);

export const updateCarcaseItemDepth = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_DEPTH",
    (newState, depth: number) => {
        if (fns.order_item.isCarcaseItem(getMergedOrderItemFromStoreWithEdit())) {
            const mergedOrderItem = getMergedOrderItemFromStore() as libTypes.CarcaseOrderItem;
            const mergedOrderItemWithEdit =
                getMergedOrderItemFromStoreWithEdit() as libTypes.CarcaseOrderItem;
            if (
                mergedOrderItem?.dEdited === depth ||
                mergedOrderItem?.d === depth
            ) {
                //delete (newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem).dEdited;
            }  else if (
                mergedOrderItemWithEdit.d === depth) {
                    (newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem
                ).dEdited = null;
                
            } else {
                (
                    newState.editOrder!.newValues.orderItem as libTypes.EditedCarcaseOrderItem
                ).dEdited = fns.order_item.set.dEdited(depth);
            }
        }
    }
);



export const updateCarcaseItemQuantity = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_QUANTITY",
    (newState, quantity: number) => {
        const mergedItem = getMergedOrderItemFromStoreWithEdit();
        console.log((newState.editOrder!.newValues.orderItem as any).quantity, quantity)
        if (
            fns.order_item.isCarcaseItem(mergedItem) ||
            fns.order_item.isAnySupplierItem(mergedItem)
        ) {
            if (mergedItem?.quantity === quantity) {
                //delete (newState.editOrder!.newValues.orderItem as any).quantity;
            } else {
                (newState.editOrder!.newValues.orderItem as libTypes.NotMissingOrderItem).quantity =
                    fns.order_item.set.quantity(quantity);
            }
        }
    }
);

export const updateCarcaseItemSortOrder = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_SORTORDER",
    (newState, sortOrder: number) => {
        const mergedItem = getMergedOrderItemFromStoreWithEdit();
        if (
            fns.order_item.isCarcaseItem(mergedItem) ||
            fns.order_item.isAnySupplierItem(mergedItem)
        ) {
            if (mergedItem?.sortOrder === sortOrder) {
                //delete (newState.editOrder!.newValues.orderItem as any).sortOrder;
            } else {
                (
                    newState.editOrder!.newValues.orderItem as libTypes.NotMissingOrderItem
                ).sortOrder = fns.order_item.set.sortOrder(sortOrder);
            }
        }
    }
);

export const updateCarcaseItemNotes = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_NOTES",
    (newState, notes: string) => {
        const mergedItem = getMergedOrderItemFromStoreWithEdit();
        if (
            fns.order_item.isCarcaseItem(mergedItem) ||
            fns.order_item.isAnySupplierItem(mergedItem)
        ) {
            if (mergedItem?.notes === notes) {
                //delete (newState.editOrder!.newValues.orderItem as any).notes;
            } else {
                (newState.editOrder!.newValues.orderItem as libTypes.NotMissingOrderItem).notes =
                    fns.order_item.set.notes(notes);
            }
        }
    }
);

export const updateCarcaseItemHanding = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_HANDING",
    (newState, handing: libTypes.CarcaseOrderItem["handing"]) => {
        const mergedItem = getMergedOrderItemFromStoreWithEdit();
        if (fns.order_item.isCarcaseItem(mergedItem)) {
            if (mergedItem?.handing === handing) {
                //delete (newState.editOrder!.newValues.orderItem as any).handing;
            } else {
                (newState.editOrder!.newValues.orderItem as libTypes.CarcaseOrderItem).handing =
                    fns.order_item.set.handing(handing);
            }
        }
    }
);

export const updateCarcaseItemPosition = createAction(
    "UPDATE_CARCASE_ORDER_ITEM_POSITION",
    (newState, position: libTypes.CarcaseOrderItem["position"]) => {
        const mergedItem = getMergedOrderItemFromStoreWithEdit();
        if (fns.order_item.isCarcaseItem(mergedItem)) {
            if (mergedItem?.position === position) {
                //delete (newState.editOrder!.newValues.orderItem as any).position;
            } else {
                (newState.editOrder!.newValues.orderItem as libTypes.CarcaseOrderItem).position =
                    fns.order_item.set.position(position);
            }
        }
    }
);

export const cancelEditOrderItem = createAction("CANCEL_EDIT_ORDER_ITEM", (newState) => {
    newState.editOrder!.newValues.orderItem = {};
});

export const saveEditOrderItem = createAction("SAVE_EDIT_ORDER_ITEM", (newState) => {
    const editOrder = getEditOrderFromStore();
    const exisitingItem = editOrder.newValues.order.items?.find(
        (item) => item.id === editOrder.newValues.orderItem.id
    );

    if (exisitingItem) {
        const newItem = {
            ...exisitingItem,
            ...editOrder.newValues.orderItem,
        };
        newState.editOrder!.newValues.order.items = editOrder.newValues.order.items
            ? editOrder.newValues.order.items.map((item) =>
                  item.id === newItem.id ? newItem : item
              )
            : ([newItem] as any);
    } else {
        newState.editOrder!.newValues.order.items = [
            ...(editOrder.newValues.order.items || []),
            editOrder.newValues.orderItem,
        ] as any;
    }
    newState.editOrder!.newValues.orderItem = {};
});

export const saveAndAddNewCarcaseItem = () => {
    saveEditOrderItem();
    createCarcaseItem();
};

export const duplicateOrderItem = createAction(
    "DUPLICATE_ORDER_ITEM",
    (newState, orderItemId: string) => {
        const mergedOrder = getMergedOrderFromStore();
        const editOrder = getEditOrderFromStore();
        const exisitingItem = mergedOrder.items?.find((item) => item.id === orderItemId);
        if (exisitingItem) {
            const newItem = {
                ...exisitingItem,
                id: uuid(),
            };
            newState.editOrder!.newValues.order.items = [
                ...(editOrder.newValues.order.items || []),
                newItem,
            ];
        }
    }
);

export const deleteOrderItem = createAction(
    "DELETE_ORDER_ITEM",
    (newState, orderItemId: string) => {
        const editOrder = getEditOrderFromStore();
        if (editOrder.newValues.order.items?.find((item) => item.id === orderItemId)) {
            newState.editOrder!.newValues.order.items = editOrder.newValues.order.items.map(
                (item) => {
                    if (item.id === orderItemId) {
                        return { ...item, _deleted: true };
                    }
                    return item;
                }
            );
        } else {
            newState.editOrder!.newValues.order.items = [
                ...(editOrder.newValues.order.items || []),
                {
                    id: orderItemId,
                    _deleted: true,
                },
            ];
        }
    }
);

export const updateOrderItemSortOrder = createAction(
    "UPDATE_ORDER_ITEM_SORT_ORDER",
    (newState, movingItemId: string, toItemId: string | 0) => {
        if(movingItemId === toItemId) return;
        const editOrder = getEditOrderFromStore();
        const mergedOrder = getMergedOrderFromStore();
        const sortedItems = fns.order_items.sortItems(mergedOrder.items);
        // insert the moving item into the sorted list after the to item using splice
        const movingItemIndex = sortedItems.findIndex((item) => item.id === movingItemId);
        const movingItem = sortedItems[movingItemIndex];
        sortedItems.splice(movingItemIndex, 1);

        const toItemIndex = toItemId === 0 ? 0 : sortedItems.findIndex((item) => item.id === toItemId);
        sortedItems.splice(toItemIndex + 1, 0, movingItem);
        // update the sort order of each item
        const newItems = sortedItems.map((item, index) => ({
            ...item,
            sortOrder: index + 1,
        }));
        newState.editOrder!.newValues.order.items = newItems.map((item) => {
            const existingItem = editOrder.newValues.order.items?.find(
                (existingItem) => existingItem.id === item.id
            );
            if (existingItem) {
                return {
                    ...existingItem,
                    sortOrder: item.sortOrder,
                };
            }
            return item;
        });
    }
);
