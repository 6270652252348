import { OrderItem } from "../../types/orderItems";
import { getLinkedSupplierProducts as getItemLinkedSupplierProducts } from "../order_item/getLinkedSupplierProducts";
import { isCarcaseItem } from "../order_item/isCarcaseItem";
import { SupplierComputedProduct } from "../../types/supplierComputedProduct";
import { LinkProduct } from "../../types/linkProduct";
import { ConstructionType } from "../../types/constructionType";

type RtnType = ({
    parent: OrderItem;
} & ReturnType<typeof getItemLinkedSupplierProducts>)[];

export function getLinkedSupplierProducts(
    orderItems: OrderItem[],
    supplierProducts: SupplierComputedProduct[],
    linkProducts: LinkProduct[],
    constructionType: ConstructionType,
    supplierDiscount: number
): RtnType {
    const returnArray = <RtnType>[];
    orderItems.forEach((orderItem) => {
        if (isCarcaseItem(orderItem)) {
            returnArray.push({
                parent: orderItem,
                ...getItemLinkedSupplierProducts(
                    orderItem,
                    supplierProducts,
                    linkProducts,
                    constructionType,
                    supplierDiscount
                ),
            });
        }
    });
    return returnArray;
}
