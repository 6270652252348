import {v4 as uuid} from 'uuid';
import { Customer } from "../../types"

export const create = (createdBy: string) : Customer => {

    return {
        defaultContact: null,
        defaultDeliveryAddress: null,
        defaultInvoiceAddress: null,
        discount: 0,
        id: uuid(),
        name: '',
        notes: '',
        supplierDiscount: 0,
        taxGroup: 'VAT',
        paymentTerms: '',
        createdBy: createdBy,
        dateCreated: new Date().getTime(),
        permissions: [],
        auth0Id: null,
        deleted_at: null
    }
    
}