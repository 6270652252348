import { taxGroups } from "../../static_data/taxGroups";
import { Customer, TaxGroup } from "../../types";
import { value } from "../validate";
import * as validate from "./validate";

export function name(name: string): string;
export function name(name: string, customer?: Customer): Customer;
export function name(name: string, customer?: Customer): Customer | string {
    // This will trow if the name is not valid
    value(name).isString().isNotWhitespace();

    if (customer) {
        return {
            ...customer,
            name,
        };
    }
    return name;
}

// set Notes
export function notes(notes: string): string;
export function notes(notes: string, customer?: Customer): Customer;
export function notes(notes: string, customer?: Customer): Customer | string {
    // This will trow if the notes is not valid
    value(notes).isString();

    if (customer) {
        return {
            ...customer,
            notes,
        };
    }
    return notes;
}

export function defaultContact(defaultContact: string | null): string | null;
export function defaultContact(defaultContact: string | null, customer?: Customer): Customer;
export function defaultContact(
    defaultContact: string | null,
    customer?: Customer
): Customer | string | null {
    if (defaultContact !== null) {
        // This will trow if the defaultContact is not valid
        value(defaultContact).isUuid();
    }

    if (customer) {
        return {
            ...customer,
            defaultContact,
        };
    }
    return defaultContact;
}

export function defaultDeliveryAddress(defaultDeliveryAddress: string | null): string | null;
export function defaultDeliveryAddress(
    defaultDeliveryAddress: string | null,
    customer?: Customer
): Customer;
export function defaultDeliveryAddress(
    defaultDeliveryAddress: string | null,
    customer?: Customer
): Customer | string | null {
    if (defaultDeliveryAddress !== null) {
        // This will trow if the defaultDeliveryAddress is not valid
        value(defaultDeliveryAddress).isUuid();
    }

    if (customer) {
        return {
            ...customer,
            defaultDeliveryAddress,
        };
    }
    return defaultDeliveryAddress;
}

export function defaultInvoiceAddress(defaultInvoiceAddress: string | null): string | null;
export function defaultInvoiceAddress(
    defaultInvoiceAddress: string | null,
    customer?: Customer
): Customer;
export function defaultInvoiceAddress(
    defaultInvoiceAddress: string | null,
    customer?: Customer
): Customer | string | null {
    if (defaultInvoiceAddress !== null) {
        // This will trow if the defaultInvoiceAddress is not valid
        value(defaultInvoiceAddress).isUuid();
    }

    if (customer) {
        return {
            ...customer,
            defaultInvoiceAddress,
        };
    }
    return defaultInvoiceAddress;
}

export function discount(discount: number): number;
export function discount(discount: number, customer?: Customer): Customer;
export function discount(discount: number, customer?: Customer): Customer | number {
    // This will trow if the discount is not valid
    value(discount).isNumber();

    if (customer) {
        return {
            ...customer,
            discount,
        };
    }
    return discount;
}

export function supplierDiscount(supplierDiscount: number): number;
export function supplierDiscount(supplierDiscount: number, customer?: Customer): Customer;
export function supplierDiscount(supplierDiscount: number, customer?: Customer): Customer | number {
    // This will trow if the supplierDiscount is not valid
    value(supplierDiscount).isNumber();

    if (customer) {
        return {
            ...customer,
            supplierDiscount,
        };
    }
    return supplierDiscount;
}

// set Notes
export function paymentTerms(paymentTerms: string): string;
export function paymentTerms(paymentTerms: string, customer?: Customer): Customer;
export function paymentTerms(paymentTerms: string, customer?: Customer): Customer | string {
    // This will trow if the paymentTerms is not valid
    value(paymentTerms).isString();

    if (customer) {
        return {
            ...customer,
            paymentTerms,
        };
    }
    return paymentTerms;
}

export function taxGroup(taxGroup: TaxGroup): TaxGroup;
export function taxGroup(taxGroup: TaxGroup, customer?: Customer): Customer;
export function taxGroup(taxGroup: TaxGroup, customer?: Customer): Customer | TaxGroup {
    // This will trow if the taxGroup is not valid
    validate.taxGroup(taxGroup, Object.keys(taxGroups));

    if (customer) {
        return {
            ...customer,
            taxGroup,
        };
    }
    return taxGroup;
}

export function auth0Id(auth0Id: string | null): string | null;
export function auth0Id(auth0Id: string | null, customer?: Customer): Customer;
export function auth0Id(auth0Id: string | null, customer?: Customer): Customer | string | null {
    if (auth0Id !== null) {
        // This will trow if the auth0Id is not valid
        value(auth0Id).isUuid();
    }

    if (customer) {
        return {
            ...customer,
            auth0Id,
        };
    }
    return auth0Id;
}
