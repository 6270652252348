
import {  Order } from "../../types";
import {v4 as uuid} from 'uuid';

export const create = (createdBy : string, existingValue?: Partial<Order>) : Order => {  
    
    
    return  {
        accountingStatus: existingValue?.accountingStatus || "NONE",
        carcaseColour: existingValue?.carcaseColour || null,
        carcaseColourPricingGroup: existingValue?.carcaseColourPricingGroup || null,
        constructionType: existingValue?.constructionType || "LAYON",
        createdBy: createdBy,
        customerId: existingValue?.customerId || null,
        customerRef: existingValue?.customerRef || '',
        dateCreated: new Date().getTime(),
        dateInvoiced: null,
        dateOrdered: existingValue?.dateOrdered || null,
        deletedCustomerName: existingValue?.deletedCustomerName || null,
        deliveryAddress: {
            addressLine1: existingValue?.deliveryAddress?.addressLine1 || "",
            addressLine2: existingValue?.deliveryAddress?.addressLine2 || "",
            addressLine3: existingValue?.deliveryAddress?.addressLine3 || "",
            city: existingValue?.deliveryAddress?.city || "",
            county: existingValue?.deliveryAddress?.county || "",
            country: existingValue?.deliveryAddress?.country || "",
            postcode: existingValue?.deliveryAddress?.postcode || "",
        },
        deliveryDate: existingValue?.deliveryDate || null,
        deliveryDateType: existingValue?.deliveryDateType || "NONE",
        deliveryNotes: existingValue?.deliveryNotes || "",
        discount: existingValue?.discount || 0,
        doorRange: existingValue?.doorRange || null,
        doorSupplier: existingValue?.doorSupplier || null,
        drawerbox: existingValue?.drawerbox || null,
        editDate: existingValue ? new Date().getTime() : null,
        editVersion: existingValue?.editVersion || 0,
        gluedWallUnits: existingValue?.gluedWallUnits || false,
        hasDoors: existingValue?.hasDoors || false,
        id: uuid(),
        invoiceAddress: {
            addressLine1: existingValue?.invoiceAddress?.addressLine1 || "",
            addressLine2: existingValue?.invoiceAddress?.addressLine2 || "",
            addressLine3: existingValue?.invoiceAddress?.addressLine3 || "",
            city: existingValue?.invoiceAddress?.city || "",
            county: existingValue?.invoiceAddress?.county || "",
            country: existingValue?.invoiceAddress?.country || "",
            postcode: existingValue?.invoiceAddress?.postcode || "",
        },
        invoiceSent: false,
        isPinned: existingValue?.isPinned || false,
        items: existingValue?.items || [],
        orderConfirmationSent: false,
        orderRef: existingValue?.orderRef || '',
        orderStatus: existingValue?.orderStatus || "QUOTE",
        orderValueEstimate: existingValue?.orderValueEstimate || 0,
        orderValueType: existingValue?.orderValueType || "ORDERITEMS",
        productionDate: existingValue?.productionDate || null,
        productionDateType: existingValue?.productionDateType || "NONE",
        productionNotes: existingValue?.productionNotes || "",
        productionStatus: existingValue?.productionStatus || "PROVISIONAL",
        payments: [],
        quoteRef: existingValue?.quoteRef || '',
        supplierDeliveryDate: existingValue?.supplierDeliveryDate || null,
        supplierDiscount: existingValue?.supplierDiscount || 0,
        voidSize: existingValue?.voidSize || null,
        taxValue: existingValue?.taxValue || 0
}};